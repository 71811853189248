
$regular-font-size: 1rem; // 12px relative to default font size
$header-font-size: 1.0833rem; // 13px

$regular-font-weight: normal;
$header-font-weight: 600;

@mixin regular-font() {
  font-size: var(--regular-font-size, $regular-font-size);
  font-weight: var(--regular-font-weight, $regular-font-weight);
}

@mixin header-font() {
  font-size: var(--header-font-size, $header-font-size);
  font-weight: var(--header-font-weight, $header-font-weight);
}

$default-font: "Open Sans";
