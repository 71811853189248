@import "../../../../../../node_modules/bootstrap/scss/variables";

// this file should contain raw color definitions

//
// Design System Colors
//

$teal:                      #30baa6;
$teal-light:                #cfefeb;
$purple:                    #ba69ae;
$purple-dark:               #9f7db5;
$purple-light:              #f2ecf6;

$red-error:                 #f34951;
$red-dark:                  #c90d16;
$red-sangria:               #730309;
$red-light:                 #e86e6a;
$yellow-warning:            #efb850;
$pink-primary:              #efa5d8;
$pink-error-background:     #feeef7;
$yellow-tertiary:           #ffe18e;
$green-success:             #5cbe89;
$green-success-light:       #82ccb5;
$green-dark:                #00ab9f;
$green-light:               #ebf8f8;

$yellow-light:              #fffbf0;

$black:                     #000;
$grey-slate:                #373a3c;
$grey-dark:                 #a0a0a0;
$grey-mid:                  #dbdbdb;
$grey-mid-darker:           #d0d0d0;
$grey-light:                #f4f5f6;
$grey-lighter:              #fcfcfc;

$secondary-button:          $grey-light;
$regular-line-color:        $grey-mid;

//////////////

$main-bg:                          #fff;

$primary-blue:                     #0055a6;
// revisit after
$primary-blue-light:               #86aed3;
$primary-blue-lighter:             #deebff;
$navy-blue:                        #316ac5;
// end revisit
$primary-contrast-color:           #fff;
$primary-border-color:             #0055a6;
$primary-highlight-color:          #004180;
$primary-highlight-border-color:   #003b73;

$secondary-blue:                   #b1cbe4;
$secondary-blue-dark:              #b1dcec;

// revisit after
$secondary-blue-light:             #d7e4f1;
$secondary-blue-lighter:           #fdfefe;
// end revisit

$secondary-contrast-color:         $black;
$secondary-border-color:           $grey-dark;
$secondary-highlight-color:        #95b8da;
$secondary-highlight-border-color: #8bb2d7;

$default-color:                    $grey-light;
$default-strong-color:             #ccc;

$default-contrast-color:           $black;
$default-border-color:             $grey-mid;
$default-highlight-color:          $grey-mid;
$default-highlight-border-color:   $grey-mid;
$default-background-text-color:    $grey-dark;

$default-disabled-color: #aaa;

///////////

$theme-colors: (
  "primary-blue":           $primary-blue,
  "primary-blue-light":     $primary-blue-light,
  "primary-blue-lighter":   $primary-blue-lighter,
  "secondary-blue":         $secondary-blue,
  "secondary-blue-light":   $secondary-blue-light,
  "secondary-blue-lighter": $secondary-blue-lighter,
  "teal":                   $teal,
  "teal-light":             $teal-light,
  "purple":                 $purple,
  "purple-light":           $purple-light,
  "red-error":              $red-error,
  "red-dark":               $red-dark,
  "yellow-warning":         $yellow-warning,
  "green-success":          $green-success,
  "green-dark":             $green-dark,
  "black":                  $black,
  "grey-slate":             $grey-slate,
  "grey-dark":              $grey-dark,
  "grey-mid":               $grey-mid,
  "grey-light":             $grey-light,
  "grey-lighter":           $grey-lighter,
);

// @each $color, $value in $theme-colors {
//   @include bg-variant(".bg-#{$color}", $value);

//   .swatch-#{$color} {
//     border: 1px solid color-yiq($value);
//     color: color-yiq($value);
//   }
// }

//
// Bootstrap Color system
//

$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;

$grays: (
  "100":                    $gray-100,
  "200":                    $gray-200,
  "300":                    $gray-300,
  "400":                    $gray-400,
  "500":                    $gray-500,
  "600":                    $gray-600,
  "700":                    $gray-700,
  "800":                    $gray-800,
  "900":                    $gray-900
);

$blue:                      #007bff;
$indigo:                    #6610f2;
$purple:                    #6f42c1;
$pink:                      #e83e8c;
$pink-light:                #f5acd5;
$red:                       #dc3545;
$orange:                    #fd7e14;
$orange-dark:               #f36f34;
$yellow:                    #ffc107;
$green:                     #28a745;
$teal:                      #20c997;
$cyan:                      #17a2b8;

$colors: (
  "blue":                   $blue,
  "indigo":                 $indigo,
  "purple":                 $purple,
  "pink":                   $pink,
  "red":                    $red,
  "orange":                 $orange,
  "yellow":                 $yellow,
  "green":                  $green,
  "teal":                   $teal,
  "cyan":                   $cyan,
  "white":                  $white,
  "gray":                   $gray-600,
  "gray-dark":              $gray-800
);

$opacity-60: 0.6;
$opacity-30: 0.3;
$opacity-20: 0.2;
$opacity-10: 0.1;

$primary:                   $primary-blue;
$secondary:                 $secondary-blue;
$success:                   $green;
$info:                      $cyan;
$warning:                   $yellow;
$danger:                    $red;
$light:                     $gray-100;
$dark:                      $gray-800;

$theme-colors: (
  "primary":                $primary,
  "secondary":              $secondary,
  "success":                $success,
  "info":                   $info,
  "warning":                $warning,
  "danger":                 $danger,
  "light":                  $light,
  "dark":                   $dark
);

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            $white;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $gray-900;

// Status pills colors
$status-overdue :#f89292;
$status-ready : #f9d997;

// Buttons colors
$btn-disabled-bg-color: #ededed;
$btn-disabled-txt-color: #aaa;
$btn-disabled-border-color: #ddd;

// GrapesJS color picker
$background-dark: rgba(0, 0, 0, 0.8);
$background-light: rgba(255, 255, 255, 0.75);
$box-shadow-1: rgba(0, 0, 0, 0.2);
$box-shadow-2: rgba(0, 0, 0, 0.34);
