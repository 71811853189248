@import "../mixins/fonts";
@import "../../../design/settings/offsets";
@import "../../../design/settings/variables";
@import "../../../design/utils/functions/borders";
@import "../../../design/utils/mixins/focused-border";

// This color is not in design system
// It's here because after David's feedback about Frame results search (table)
$frame-header-background: #fffce8;

.ReactTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &.-highlight {
    .rt-tbody {
      .rt-tr:not(.-padRow) {
        &:hover {
          background: $primary-blue-lighter;
        }

        &[aria-selected="true"] {
          background: $navy-blue;
          color: $white;

          a {
            color: $white;
          }
        }
      }

      .multi-selected {
        background: $navy-blue;
        color: $white;
        z-index: 1;

        .rt-tr {
          &:hover {
            background: $navy-blue;
          }

          &[aria-selected="true"] {
            @include focused-border();

            &:hover {
              background: $navy-blue;
            }

            &::after {
              box-shadow: none;
              top: -1px;
            }
          }
        }
      }

      .pinned-row {
        background: $yellow-light;
        position: sticky;
        z-index: 1;

        .rt-tr {
          &[aria-selected="true"] {
            background: $yellow-light;
            color: $black;

            @include focused-border();

            &::after {
              box-shadow: none;
              top: -1px;
            }
          }
        }
      }
    }
  }

  &.-alt-padding {
    .rt-th,
    .rt-td {
      padding: 0.5rem;
    }
  }

  .rt-td.min-padding {
    padding: 2px;
  }

  .rt-table {
    align-items: stretch;
    background-color: $table-bg;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    height: 100%;
    margin-bottom: $spacer;
    outline: none;
    overflow: auto;
    position: relative;
    width: 100%;

    &:focus {
      .rt-tr:not(.-padRow) {
        &[aria-selected="true"] {
          @include focused-border();

          &::after {
            top: -1px;
          }

          .rt-td {
            border-bottom-color: transparent;
          }
        }
      }

      .pinned-row {
        .rt-tr {
          &[aria-selected="true"] {
            @include focused-border();

            &::after {
              top: -1px;
            }

            .rt-td {
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
  }

  .rt-thead {
    background-color: $grey-light;
    border-bottom: solid 1px $regular-line-color;
    display: flex;
    flex-direction: column;
    user-select: none;

    .rt-th {
      @include header-font;

      border-bottom: none;
      position: relative;
      vertical-align: bottom;

      &::after {
        content: " ";
        height: 0;
        position: absolute;
        right: 10px;
        top: 16px;
        width: 0;
      }

      &.-sort-asc {
        &::after {
          border-bottom: 5px solid #333;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 0 solid transparent;
        }

        &:hover::after {
          border-bottom: 5px solid #888;
        }
      }

      &.-sort-desc {
        &::after {
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #333;
        }

        &:hover::after {
          border-top: 5px solid #888;
        }
      }
    }
  }

  .rt-tbody {
    display: inline-flex;
    flex-direction: column;
    overflow: auto;
  }

  .rt-tr-group {
    align-items: stretch;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .rt-tr {
    display: inline-flex;
    flex: 1 0 auto;
  }

  .rt-th,
  .rt-td {
    border-bottom: $table-border-width solid $table-border-color;
    flex: 1 0 0;
    overflow: hidden;
    padding: $table-cell-padding;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;

    &.-hidden {
      display: none;
    }
  }

  .-cursor-pointer {
    cursor: pointer;
  }

  .rt-resizable-header {
    overflow: visible;

    .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      overflow: hidden;
    }
  }

  .rt-resizer {
    bottom: 0;
    cursor: col-resize;
    display: inline-block;
    position: absolute;
    right: -18px;
    top: 0;
    width: 36px;
    z-index: 10;
  }

  .rt-resizing {
    .rt-th,
    .rt-td {
      cursor: col-resize;
      /* stylelint-disable declaration-no-important  */
      transition: none !important;
      user-select: none;
    }
  }

  .-loading {
    display: none;
  }

  &.TreatmentsList {
    .rt-thead.-header {
      display: none;
    }

    .rt-table {
      margin-bottom: 0;
    }

    .rt-td,
    .rt-th {
      padding: $xsmall-padding;
    }

    .rt-td {
      border-color: $grey-light;
    }
  }

  &.TableSearchResults {
    .rt-table {
      margin-bottom: 0;
    }

    .rt-td,
    .rt-th {
      padding: $xsmall-padding;
    }
  }
}
