@import "../../settings/variables";

// this mixin is helpful in creating a focus-like border
// we need this on selected elements that are children of a focused list/table/grid

@mixin focused-border() {
  border-radius: $default-border-radius;
  position: relative;

  &::after {
    border: medium-border($input-focus-border-color);
    border-radius: $default-border-radius;
    bottom: 0;
    box-shadow: 0 0 0.5rem $input-focus-border-color;
    content: "";
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}
