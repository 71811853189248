@import "../../settings/variables";

.react-contextmenu {
  background-clip: padding-box;
  background-color: $main-bg;
  border: 1px solid $grey-mid;
  border-radius: $border-radius;
  color: $grey-slate;
  font-size: $regular-font-size;
  margin: 2px 0 0;
  min-width: 160px;
  opacity: 0;
  outline: none;
  padding: $small-padding 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: $grey-slate;
  cursor: pointer;
  font-weight: normal;
  line-height: $line-height-lg;
  padding: $xsmall-padding $small-padding;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  background-color: $grey-light;
  border-color: $grey-light;
  color: $gray-700;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: $grey-mid;
  color: $grey-light;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid $grey-mid;
  cursor: inherit;
  margin-bottom: 3px;
  padding: $xsmall-padding 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: $grey-mid;
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

// .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
// }

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item::after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}
