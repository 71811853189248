// Import parts of Bootstrap as needed

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

.has-float-label {
  display: block;
  position: relative;

  .label,
  .has-float-label > span {
    cursor: text;
    font-size: 75%;
    left: 0.75rem;
    line-height: 1;
    opacity: 1;
    padding: 0 1px;
    position: absolute;
    top: -0.5em;
    transition: all 0.2s;
    z-index: 3;

    &::after {
      background: #fff;
      content: " ";
      display: block;
      height: 2px;
      left: -0.2em;
      position: absolute;
      right: -0.2em;
      top: 50%;
      z-index: -1;
    }
  }

  .form-control::-webkit-input-placeholder {
    opacity: 1;
    transition: all 0.2s;
  }

  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
  }

  .form-control:placeholder-shown:not(:focus) + * {
    font-size: 125%;
    opacity: 0.5;
    top: 0.3em;
  }
}

.input-group {
  .has-float-label {
    display: table-cell;
  }

  .form-control {
    border-radius: 0.25rem;
  }

  .has-float-label:not(:last-child) {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;

    .form-control {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
    }
  }

  .has-float-label:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    .form-control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
