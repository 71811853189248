@import "../../settings/variables";

.treatmentsTable {
  .e-gridheader {
    display: none;
  }

  .e-gridcontent {
    border-top: thin-border(#dee2e6);
  }
}

.treatmentsDropdown,
.treatmentsSideData {

  // Like we know we don't want to use Syncfusion so these styles
  // should be removed after changing AutoComplete component from Syncfusion
  // to ReactSelect Component.

  &.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  &.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  &.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error),
  &.e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: $gray-400;
    box-shadow: none;
    outline: none;

    &:hover {
      border-color: $gray-400;
    }
  }

  &.e-input-group.e-control-wrapper.e-ddl {
    height: calc(1.8125rem + 2px);

    @include regular-font;
  }

  .e-control.e-autocomplete.e-lib.e-input.e-keyboard {
    height: calc(1.8125rem + 2px);

    @include regular-font;

    &::placeholder {
      @include regular-font;
    }

    &:focus {
      outline: none;
    }
  }

  .e-control.e-dropdownlist.e-lib.e-input {
    height: calc(1.8125rem + 2px);
  }

  span.e-input-group-icon.e-ddl-icon.e-search-icon {
    border-color: var(--primary-color-contrast);
    max-height: calc(1.8125rem + 2px);
    min-height: auto;

    &:hover {
      background-color: var(--primary-color-contrast);
      border-color: var(--primary-color-contrast);
      color: var(--secondary-color-contrast);
    }
  }

  .e-dropdownbase .e-list-item {
    @include regular-font;

    height: unset;
    line-height: inherit;
    min-height: inherit;
    padding-right: 0;
    text-indent: 0;
  }

  .e-list-item.e-hover {
    background-color: var(--table-row-hover-background);
  }

  .e-content.e-dropdownbase {
    padding: 0;
  }
}
