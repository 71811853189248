@import "index";
// CONSIDER AFTER TO MOVE IT TO BOOTSTRAP OVRERRIDE
// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      -apple-system, "BlinkMacSystemFont", "Open Sans", "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;
$font-family-base:            $default-font, $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * 0.875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                2rem;
$h2-font-size:                1.6rem;
$h3-font-size:                1.2rem;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$h1-line-height:              1.125;
$h2-line-height:              1.25;
$h3-line-height:              $line-height-base;
$h4-line-height:              $line-height-base;
$h5-line-height:              $line-height-base;
$h6-line-height:              $line-height-base;

$h1-font-weight:              300;
$h2-font-weight:              500;
$h3-font-weight:              700;
$h4-font-weight:              500;
$h5-font-weight:              500;
$h6-font-weight:              500;

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        inherit;
// $headings-font-weight:        500;
// $headings-line-height:        1.2;
$headings-color:              inherit;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$text-muted:                  $gray-600;
