// Imports from Bootstrap
@import "../../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../../node_modules/bootstrap/scss/mixins";
@import "../fonts";

// THIS FILE SHOULD BE REMOVED AFTER

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Links
//
// Style anchor elements.

$link-color:                theme-color("primary");
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// GRID TO BE REMOVED AFTER REFACTORING

$medium-screen-size: 1735px;
$large-screen-size: 1900px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;

$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1;

/* stylelint-disable declaration-no-important  */
$input-height-sm: var(--input-height-sm) !important;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               0.25rem;
$border-radius-lg:            0.3rem;
$border-radius-sm:            0.2rem;

$box-shadow-sm:               0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow:                  0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-lg:               0 1rem 3rem rgba($black, 0.175);

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

$caret-width:                 0.3em;

$transition-base:             all 0.2s ease-in-out;
$transition-fade:             opacity 0.15s linear;
$transition-collapse:         height 0.35s ease, width 0.35s ease;

$page-offset-top:                   49px;

// REMOVE AFTER REFACTOR PATIENT DASHBOARD
$button-spacing:                    15px;

// Z-index master list
//
// Warning: Avoid customizing these values. They"re used for a bird"s eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-nav-menu:                   100;
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-table-spinner:              1039;
$zindex-modal-backdrop:             1040;
$zindex-modal:                      1050;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;
$zindex-patient-fixed:              2;

// Footer Container
//
// Variables for the Footer Container
// TO REMOVE AFTER SALES REFACTORING
$footer-container-margin:          15px 0;

// Container Headers
//
// Vaiables for Container Headers
// REMOVE AFTER REFACTOR EXAMINATIONS
$box-container-header-height : 3rem;
$tab-title-height: $box-container-header-height;
$tab-button-height-offset : 0.1rem;
$tab-button-height : $tab-title-height - $tab-button-height-offset;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:                0.5rem 0.25rem;

$table-bg:                          transparent;
$table-accent-bg:                   rgba($black, 0.05);
$table-hover-bg:                    rgba($black, 0.075);
$table-active-bg:                   $table-hover-bg;

$table-border-width:                $border-width;
$table-border-color:                $gray-300;

$table-head-bg:                     $gray-200;
$table-head-color:                  $gray-700;

// App Bar
//
// Variables for the App Bar and it's sub-components

$appbar-height:                     5vh;

// Buttons
//
// Variables for buttons

$btn-min-width:                     50px;
$xs-btn-min-width:                  30px;
$btn-min-height:                    calc(1.8125rem + 2px);
$xs-btn-min-height:                 calc(1.75rem + 2px);

// Forms
//
// Variables for forms

// Modals
//
// Variables for modals
$modal-xl:                          75%;

// Dashboard Links
//
// Variables for dashboard link styling
// REMOVE AFTER REFACTORING DASHBOARD
$dashboard-link-color:              #373a3c;
$dashboard-link-bg-color:           #f4f5f6;

// Navigation Menu

$highlight-color: #deebff;

//
// Variables for the Navigation Menu

$nav-menu-width-closed:                    3.5rem;
$nav-menu-width-open:                      14rem;
$nav-menu-item-height:                     4.5rem;

// Dashboard Links
//
// Variables for dashboard link styling
$dashboard-link-width:              120px;
$dashboard-link-height:             100px;
$dashboard-link-padding:            10px;
$dashboard-link-margin:             16px;
$dashboard-link-color:              #373a3c;
$dashboard-link-bg-color:           #f4f5f6;
$dashboard-link-icon-height:        40px;

// Dashboard groups

$dashboard-group-margin: 35px;
