$text-icon-space: 1em;

$button-h-padding: 0.333rem;
$button-v-padding: 0.666rem;

$xsmall-padding: 0.25rem;
$small-padding: 0.5rem;
$default-padding: 1rem;
$large-padding: 3rem;

$line-height-compressed: 1.2;

$default-border-radius: 0.3em;

// Bootstrap offsets
$dropdown-toggle-caret-margin: 1em;
