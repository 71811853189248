@import "../../settings/variables";
@import "../../settings/defaults";
@import "../../settings/offsets";

/* stylelint-disable declaration-no-important  */

.dropdown-toggle {
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;

  &::after {
    margin-left: $dropdown-toggle-caret-margin;
  }

  &:hover {
    background-color: unset;
  }

  &:active {
    background-color: unset !important;
  }

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
    outline: 0;
  }
}

.show > .btn-secondary.dropdown-toggle {
  background-color: unset;
  border-color: $input-focus-border-color;
  box-shadow: $input-box-shadow, $input-focus-box-shadow;
  outline: 0;

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
    outline: 0;
  }
}

$option-padding: 0.25rem 0.5rem;
$select-option-is-selected: $navy-blue;
$select-option-is-hovered:  $primary-blue-lighter;

.dropdown-item {
  background-color: $white;
  padding: $option-padding;

  &:hover {
    background-color: $select-option-is-hovered;
  }

  &:active {
    color: $black;
  }

  &:focus {
    background-color: $select-option-is-hovered;
    outline: none;
  }
}

.btn {
  min-width: $btn-min-width;

  @include regular-font;

  &-sm {
    height: $btn-min-height;
  }

  &-xs {
    height: $xs-btn-min-height;
    line-height: 0;
    min-width: $xs-btn-min-width;
  }
}

.btn:disabled {
  background-color: $btn-disabled-bg-color;
  border: 1px solid $btn-disabled-border-color;
  color: $btn-disabled-txt-color;
  cursor: not-allowed;
}

.form-control-sm {
  @include regular-font;
}

textarea.form-control:focus,
input.form-control:focus {
  border: 1px solid $gray-400;
  box-shadow: none;
  outline: none;
}

textarea[rows="1"] {
  line-height: 18px;
  overflow: hidden;
  padding: 0 0.5rem;
}

@media (min-width: #{$medium-screen-size}) {
  textarea[rows="1"] {
    line-height: 19px;
  }
}

@media (min-width: #{$large-screen-size}) {
  textarea[rows="1"] {
    line-height: 21px;
  }
}

.form-control.is-invalid {
  background-color: $pink-error-background;
  background-image: none;
  padding-right: 0.5rem;

  &:focus {
    box-shadow: none;
  }
}

.form-control.has-warning {
  background-image: none;
  border-color: $yellow-warning;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding: 0;
}

.row {
  margin: 0;
}

div:focus {
  outline: none;
}
