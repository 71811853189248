@import "../../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../../node_modules/bootstrap/scss/mixins";
@import "../../settings/variables";

@include media-breakpoint-up(md) {
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

.modal-dialog-auto-width {
  max-width: fit-content;
  min-width: auto;
}
